<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">現有庫存查詢</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div> -->
        <button
          v-show="grid.canCreateRow && !grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome
            icon="plus"
            type="fas"
            class="w-4 h-4 mr-1"
          />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedWarehouse"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇庫別</option>
              <option
                v-for="(item, index) in warehouses"
                :key="'warehouse-' + index"
                :value="item.Id"
                class="py-3"
              >{{ item.Name }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedBrand"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇品牌</option>
              <option
                v-for="(item, index) in country"
                :key="'country-' + index"
                :value="item.Id"
                class="py-3"
              >{{ item.Name }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedPiece"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇片數</option>
              <option value="0-300">300片以下</option>
              <option value="301-500">301-500片</option>
              <option value="501-1000">501-1000片</option>
              <option value="1001-1500">1001-1500片</option>
              <option value="1501-999999">1500片以上</option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="inputBarcode"
              placeholder="請輸入條碼"
            />
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>

        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome
                icon="file-export"
                class="w-4 h-4 mr-2"
              /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome
                    icon="file-csv"
                    class="w-4 h-4 mr-2"
                  /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome
                    icon="file"
                    type="fas"
                    class="w-4 h-4 mr-2"
                  /> HTML
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row }">
            <vxe-form
              class="ml-3"
              v-bind="formOptions"
              :data="row.Xml"
            >
              <template #xml="{ data }">
                <vxe-table
                  stripe
                  resizable
                  border
                  round
                  :data="data?.DeltaColumns"
                >
                  <vxe-table-column
                    field="Name"
                    title="欄位"
                  ></vxe-table-column>
                  <vxe-table-column
                    field="Original"
                    title="修改前"
                  ></vxe-table-column>
                  <vxe-table-column
                    field="Current"
                    title="修改後"
                    :resizable="false"
                  ></vxe-table-column>
                </vxe-table>
              </template>
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref, Operator, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({});
    const selectedBrand = ref("");
    const selectedPiece = ref("");
    const selectedWarehouse = ref("");
    const inputBarcode = ref("")
    const country = ref([]);
    const warehouses = ref([]);

    var printColumns = [
      { field: 'CreatedTime' },
      { field: 'UserName' },
      { field: 'ActionName' },
      { field: 'UserName' },
      { field: 'EntityTypeName' },
      { field: 'EntityName' },
    ]

    const gridOptions: GridOptions = {
      title: '操作紀錄',
      mode: "inline",
      canCreate: false,
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '操作紀錄', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '操作紀錄', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Warehouse.Name', title: '倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Brand.Name', title: '品牌', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Barcode', title: '條碼', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Name', title: '產品', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: 'SaftyQuantity', title: '安全庫存', showHeaderOverflow: true, showOverflow: true, align: "right",
          formatter: ({ cellValue }) => cellValue ? CloudFun.utils.formatMoney(cellValue) : "",
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0 },
          }
        },
        { field: 'Quantity', title: '數量', showHeaderOverflow: true, showOverflow: true, align: "right", resizable: false, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue), sortable: true },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedWarehouse.value !== "") {
            params.condition!.and("WarehouseId", Operator.Equal, selectedWarehouse.value);
          }
          if (selectedBrand.value !== "") {
            params.condition!.and("Product.BrandId", Operator.Equal, selectedBrand.value?.toString());
          }
          if (selectedPiece.value !== "") {
            params.condition!.and("Product.ProductAttributeValues.AttributeValue.DecimalValue", Operator.Equal, selectedPiece.value);
          }
          if (inputBarcode.value !== "" && /^[0-9]+$/.test(inputBarcode.value)) {
            params.condition!.and("Product.Barcode", Operator.Equal, inputBarcode.value)
          }
          return model.dispatch('inventory/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('inventory/query') : undefined,
        save: model ? (params) => model.dispatch("inventory/save", params) : undefined,
      },
    }

    const getCountry = async () => {
      var sortings = [{ column: "Ordinal", order: 0 }];
      return model?.dispatch("brand/query", { sortings }).then((data) => {
        data = data.filter((item: any) => {
          return item.Name !== null
        }).sort((a: any, b: any) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        return data;
      }).catch((error) => {
        console.log(error)
      })
    }

    onMounted(async () => {
      country.value = await getCountry();
      warehouses.value = await model?.dispatch('warehouse/query');
    })

    return {
      grid,
      gridOptions,
      selectedBrand,
      selectedPiece,
      selectedWarehouse,
      country,
      warehouses,
      inputBarcode
    }
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
  }
})
</script>
