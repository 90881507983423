
import CloudFun, { defineComponent, ref, Operator, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({});
    const selectedBrand = ref("");
    const selectedPiece = ref("");
    const selectedWarehouse = ref("");
    const inputBarcode = ref("")
    const country = ref([]);
    const warehouses = ref([]);

    var printColumns = [
      { field: 'CreatedTime' },
      { field: 'UserName' },
      { field: 'ActionName' },
      { field: 'UserName' },
      { field: 'EntityTypeName' },
      { field: 'EntityName' },
    ]

    const gridOptions: GridOptions = {
      title: '操作紀錄',
      mode: "inline",
      canCreate: false,
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '操作紀錄', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '操作紀錄', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Warehouse.Name', title: '倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Brand.Name', title: '品牌', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Barcode', title: '條碼', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Product.Name', title: '產品', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: 'SaftyQuantity', title: '安全庫存', showHeaderOverflow: true, showOverflow: true, align: "right",
          formatter: ({ cellValue }) => cellValue ? CloudFun.utils.formatMoney(cellValue) : "",
          editRender: {
            name: '$input',
            immediate: true,
            props: { type: 'number', min: 0 },
          }
        },
        { field: 'Quantity', title: '數量', showHeaderOverflow: true, showOverflow: true, align: "right", resizable: false, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue), sortable: true },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedWarehouse.value !== "") {
            params.condition!.and("WarehouseId", Operator.Equal, selectedWarehouse.value);
          }
          if (selectedBrand.value !== "") {
            params.condition!.and("Product.BrandId", Operator.Equal, selectedBrand.value?.toString());
          }
          if (selectedPiece.value !== "") {
            params.condition!.and("Product.ProductAttributeValues.AttributeValue.DecimalValue", Operator.Equal, selectedPiece.value);
          }
          if (inputBarcode.value !== "" && /^[0-9]+$/.test(inputBarcode.value)) {
            params.condition!.and("Product.Barcode", Operator.Equal, inputBarcode.value)
          }
          return model.dispatch('inventory/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('inventory/query') : undefined,
        save: model ? (params) => model.dispatch("inventory/save", params) : undefined,
      },
    }

    const getCountry = async () => {
      var sortings = [{ column: "Ordinal", order: 0 }];
      return model?.dispatch("brand/query", { sortings }).then((data) => {
        data = data.filter((item: any) => {
          return item.Name !== null
        }).sort((a: any, b: any) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        return data;
      }).catch((error) => {
        console.log(error)
      })
    }

    onMounted(async () => {
      country.value = await getCountry();
      warehouses.value = await model?.dispatch('warehouse/query');
    })

    return {
      grid,
      gridOptions,
      selectedBrand,
      selectedPiece,
      selectedWarehouse,
      country,
      warehouses,
      inputBarcode
    }
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
  }
})
